.report-view-parent-back-button{
    margin-top: 6rem;
    margin-left: 4rem;
    margin-bottom: 1rem;
    color: blue;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.report-view-parent{
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 5px #F0F0F0;
    border-radius: 5px;
    color: #393939;
}

.report-view-child{
    width: 100%;
    border: 1px #F0F0F0;
}

/* SECTIONS */
.report-view-section-parent{
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
}

.section-parent-header{
    border: 1px solid #F0F0F0;
    display: flex;
}

.section-parent-childs{
    background-color: orange;
    width: 100%;
}

    
/* Appointment Details CSS */
.section-parent-app-dtails{
    margin-top: -10px;
    margin-left: 15px;
    padding: 35px;
    text-decoration-style: #393939;
    /* display: flex; */    
}

.section-app{
    margin-left: 0px;
}

.section-childrens{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* width: 100%; */
    flex-wrap: wrap;
}

/* Appointment Heading */
.appointment-heading{
    margin-left: 15px;
    color:#393939;
    border: 1px solid #F0F0F0;

}

/* Attachment CSS */
.files{
    margin-left: 15px;
    
}

.attachments {
    text-align: center;
    width: 35%;
    margin-left: 19px;
    margin-bottom: 19px;
    margin-top: 19px;
    /* padding: 1rem 2rem 0rem 2rem; */
}
.fileName{
    display:flex;
    flex-direction: column;
    margin-top: 10px;
    margin-left: 15px;
    color: #989898
}

