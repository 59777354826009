.edit-container {
    background-color: #FCFCFC;
    border: 1px solid var(--border-color);
    border-radius: .2rem;
    margin: 2rem 2rem 2rem 2rem;
    /* padding: rem; */
}

.edit-content {
    display: flex;
    justify-items: center;
    align-items: baseline;
    padding: 1rem 1rem 0rem 1rem;
    border-radius: .5rem;
    background-color: white;

}

.edit-body {
    width: 20%;
}

.edit-doctor-img {
    border: 1px solid var(--border-color);
    /* width: 90%; */
    border-radius: .5rem;
    text-align: center;
    cursor: pointer;
}

.edit-doctor-img img {
    width: 100%;
    padding: .5rem;
}

.edit-doctor-img h4 {
    text-align: center;
    color: #2F77B6;
    font-weight: normal;
    margin: 0;
    padding-bottom: .2rem;
}

.edit-body-right {
    width: 80%;
    /* padding-top: 2rem; */
    margin-left: 1.5rem;
    border-left: 1px solid var(--border-color);
}

.edit-form {
    display: flex;
    justify-content: space-between;
    padding: 0rem 1rem 0rem 1rem;
}

.edit-name {
    width: 48%;
}

.edit-select {
    width: 22.5%;
}

.edit-age {
    width: 22.5%;
}

.edit-form input[type=text],
.user-date {
    width: 100%;
    padding: 0.5rem;
    margin-top: 0.5rem;
    /* margin: 0.5rem 0; */
    height: 2.8rem;
    display: block;
    outline: none;
    background-color: white;
    border: 1px solid var(--argent);
    border-radius: 0.25rem;
    box-sizing: border-box;
    font-size: 1rem;
}

.edit-form input[type=number],
.user-date {
    width: 100%;
    padding: 0.5rem;
    margin-top: 0.5rem;
    /* margin: 0.5rem 0; */
    height: 2.8rem;
    display: block;
    outline: none;
    background-color: white;
    border: 1px solid var(--argent);
    border-radius: 0.25rem;
    box-sizing: border-box;
    font-size: 1rem;
}

.edit-select-field {
    width: 100%;
    height: 2.8rem;
    padding: 0.5rem;
    margin-top: 0.5rem;
    /* margin: 0.5rem 0; */
    display: block;
    outline: none;
    background-color: white;
    border: 1px solid var(--argent);
    border-radius: 0.25rem;
    box-sizing: border-box;
    text-transform: capitalize;
    font-size: 1rem;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url('../../../assets/images/Path\ -12.png');
    background-repeat: no-repeat;
    background-size: .75rem;
    background-position: calc(100% - 3px);
}

.edit-mobile {
    width: 48%;
}

.edit-email {
    width: 48.5%;
    display: flex;
    flex-direction: column;
}
.edit-textarea {
    padding: 0rem 1rem 0rem 1rem;
}

.edit-textarea textarea {
    border: 1px solid var(--argent);
    border-radius: 0.25rem;
    box-sizing: border-box;
    width: 100%;
    padding: 0.5rem 1.25rem;
    margin: 0.5rem 0;
    display: block;
    height: 10rem;
    outline: none;
    font-size: 1rem;
}

.edit-file {
    width: 48%;
    /* display: flex; */
    /* width: 100%; */
}

.edit-file input {
    width: 100%;
}

.searchcard-edit {
    border: 1px solid var(--argent);
    height: 3.2rem;
    background-color: #fff;
    border-radius: 0.2rem;
    display: flex;
    overflow: hidden;
    align-items: center;
    margin-top: .5rem;

}

.searchcard-edit .wwd {
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    outline: none;
    font-size: 1rem;
    /* color: var(--argent); */
    padding: 0 1rem;
    text-transform: none;
}

.searchcard img {
    /* font-size: 1.5rem; */
    width: 3rem;
    padding-right: 1rem;


    cursor: pointer;
}

.edit-saudi {
    width: 49.7%;
    padding: 0rem 1rem 0rem 1rem;
}

.edit-saudi input {
    width: 100%;
    padding: 0.5rem 1.25rem;
    margin: 0.5rem 0;
    display: block;
    border: 1px solid var(--argent);
    border-radius: 0.25rem;
    font-size: 1rem;
    box-sizing: border-box;
    outline: none;
}

.edit-table {
    padding: 0rem 1rem 1rem 1rem;
    width: 100%;
}

.error {
    color: red;
    height: 1rem;
    margin-bottom: 1rem;
    font-size: unset !important;
}

.edit-table-input-s {
    border: 1px solid var(--argent);
    padding: .5rem;
    width: 100%;
    outline: none;
    border-radius: 0.25rem;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url('../../../assets/images/Path\ -12.png');
    background-repeat: no-repeat;
    background-size: .75rem;
    background-position: calc(100% - 3px);
}

.table-content-edit {
    width: 100%;
    border-collapse: collapse;
    margin-top: 0rem;
    overflow-x: auto;
}

.table-content-edit thead {
    border: 1px solid var(--argent);
    background-color: #D5F1FF;
}

.table-content-edit thead th {
    padding: 1rem;
    text-align: left;
    font-size: 0.75rem;
    color: #707070;
    font-weight: normal;
}

.table-content-edit tbody tr {
    border: 1px solid var(--argent);
}

.table-content-edit tbody td {
    padding: 1rem;

}

.service-add {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.check-day {
    display: flex;
    align-items: center;
}

.schedule-table {
    width: 100%;

    padding: 0.4rem;
    /* margin: 0.5rem 0; */
    display: block;
    border: 1px solid var(--argent);
    border-radius: 0.25rem;
    font-size: 1rem;
    box-sizing: border-box;
    outline: none;
}

.edit-footer {
    border-top: 1px solid var(--border-color);
    background-color: white;
}

.edit-button-content {
    display: flex;
    justify-content: right;
    align-items: center;
    padding: 1rem;
}

.discard-btn {
    border: 1px solid var(--steel-blue);
    border-radius: 2rem;
    font-size: 1rem;
    padding-left: 2rem;
    color: var(--steel-blue);
    padding-right: 2rem;
    background-color: white;
    padding-top: .5rem;
    padding-bottom: .5rem;
    margin-right: 1rem
}

.save-btn-1 {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    margin: 0;
}

.save-btn {
    /* border: 1px solid var(--steel-blue); */
    border-radius: 2rem;
    font-size: 1rem;
    padding: 0.6rem 1rem 0.6rem 1rem;
    color: white;
    border: none;
    /* margin-bottom: 0.5rem; */
    background-color: var(--steel-blue);

}

.family-table {
    padding: .5rem;

    width: 100%;
    border-collapse: collapse;
}

.family-table th {
    background-color: #D5F1FF;
    text-align: left;
    padding: 1rem;
    margin: 0.5rem;
    color: #707070;
    font-size: .75rem;

    /* border-radius: 0.75rem; */
    font-weight: normal;

}

.family-table tr {
    border: 1px solid var(--argent);

    /* padding: 1rem; */
}

.family-table tbody td {
    padding: 1rem;
    font-size: 1rem;
    color: #1A1919;
    font-weight: normal;
    text-align: left;
}

.family-input {
    border: 1px solid var(--argent);
    padding: 0.5rem;
    outline: none;
    height: 2.5rem;
    font-size: 1rem;
    width: 100%;
    border-radius: 0.25rem;
}

.family-input-select {
    border: 1px solid var(--argent);
    padding: 0.5rem;
    outline: none;
    height: 2.5rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url('../../../assets/images/Path\ -12.png');
    background-repeat: no-repeat;
    background-size: .75rem;
    background-position: calc(100% - 3px);
    width: 100%;
}

@media (max-width:720px) {
    .edit-content {
        flex-direction: column;
    }

    .edit-body {
        width: 100%;
    }

    .edit-body-right {
        width: 100%;
        margin: 0;
        border: none;
        border-top: 1px solid var(--argent);
        margin-top: 1rem;
    }

    .edit-form {
        flex-direction: column;
        padding: 0;
    }

    .edit-name {
        width: 100%;
    }

    .edit-select {
        width: 100%;
    }

    .edit-age {
        width: 100%;
    }

    .edit-mobile {
        width: 100%;
    }

    .edit-email {
        width: 100%;
    }

    .edit-file {
        width: 100%;
    }

    .edit-textarea {
        padding: 0;
    }

    .edit-saudi {
        width: 100%;
        padding: 0;
    }

    .edit-button-content {
        flex-direction: column;
    }

    .discard-btn {
        width: 100%;
        margin: 0;
    }

    .save-btn {
        width: 100%;
        margin-top: 1rem;
    }

    .schedule-table {
        width: 100%;
    }

    .family-input {
        /* width: 100%; */
        /* padding: 0; */
    }

    .family-input-select {
        width: 100%;
    }
}