.edit-home-container {
    width: 80%;
    margin: 8rem auto;
}

.edit-home-container h3 {
    margin-left: .5rem;
    margin-bottom: 1rem;
}

.home-wrapper {
    border: 2px solid var(--border-color);
    border-radius: 00.625rem;
    background-color: white;
}

.home-form {
    display: flex;
    align-items: center;
    border-bottom: 2px solid var(--border-color);
    padding: 1rem;
}

.home-input-content {
    width: 50%;
    margin: 0 .5rem;
}

.home-input-content-full {
    width: 100%;
}

.home-input-content-20 {
    width: 20%;
}

.home-input-content-40 {
    width: 40%;
}

label {
    font-weight: bold;
}

.home-input-content label {
    font-weight: bold;
}

.home-input-field {
    width: 100%;
    padding: .8rem;
    border: 1px solid #D8D8D8;
    border-radius: 0.25rem;
    outline: none;
    margin-top: .3rem;
    font-size: 1rem;
    min-height: 3rem;
}

.home-input-field-textarea {
    min-height: 10rem;
    resize: vertical;
}

.input-invalid-message {
    height: 1rem;
    /*background: red;*/
    color: red;
    font-size: 80%;
    padding: 0.1rem .2rem;

}

.input-invalid {
    border: 1px solid red;
}

.home-section-one {
    padding: 1rem;
    border-bottom: 1px solid var(--border-color);
}

.section-one-input {
    width: 82.7%;
    margin-top: 1rem;
}

.section-bg-input {
    width: 40%;
    margin-top: 1rem;
}

.title-input-container {
    display: flex;
    align-items: center;
    margin-top: 1rem;
}

.file-input {
    display: flex;
    align-items: center;

}

.delete-icon {
    color: var(--steel-blue);
    width: 2rem;
    height: 2rem;
    margin-left: 1rem;
    cursor: pointer;
}

.home-btn-content {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    box-shadow: 0px -3px 6px #0000000F;
    width: 100%;
    padding: 1rem;
    bottom: 0;
    left: 0;
}

.home-section-one p{
    font-size: 1.5rem;
}

.home-mark {
    display: flex;
    align-items: center;
}

.home-preview {
    background-color: white;
    color: var(--steel-blue);
    border: 1px solid var(--steel-blue);
    outline: none;
    padding: 0.5rem 3rem 0.5rem 3rem;
    margin-right: 1rem;
}

.md-delete {
    font-size: 1.5rem;
    color: var(--steel-blue);
    cursor: pointer;
}

.home-web-content {
    margin-left: 1.5rem;
}

.home-title-container {
    margin-top: 0 !important;
}

.category-name {
    display: flex;
    align-items: center;
}