@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;0,800;0,900;1,200;1,300&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700|Sanchez&display=swap");

:root {
    --old-lace: #fff4e8;
    --mazie: #f4c548;
    --honeydew: #e9ffe8;
    --yellow-green: #16cb0e;
    --beer: #f28519;
    --picton-blue: #43afe9;
    --tufts-blue: #3a9cd8;
    --steel-blue: #2f77b6;
    --eerie-black: #1a1919;
    --dark-silver: #707070;
    --spanish-gray: #989898;
    --argent: #c4c0c0;
    --chinese-silver: #cbcbcb;
    --flash-white: #f0f0f0;
    --footer: #e6f6ff;
    --border-color: #F0F0F0;
}

* {
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
    /* transition: 0.9s linear; */
    text-decoration: none;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

body {
    background-color: #FCFCFC;
    scroll-behavior: smooth;
}

input::-ms-reveal,
input::-ms-clear {
    display: none;
}

.Toastify__toast--success {
    background-color: #E9FFE8 !important;
    border: 1px solid #16CB0E !important;
    border-radius: 4px !important;
}

.Toastify__toast--error {
    background-color: #E9FFE8 !important;
    border: 1px solid #16CB0E !important;
    border-radius: 4px !important;
}

.Toastify__toast--info {
    background-color: #E2F5FF !important;
    border: 1px solid #43AFE9 !important;
    border-radius: 4px !important;
}

.Toastify__toast-container--top-center {
    width: 50% !important;
}

section {
    display: flex;
    justify-content: space-between;
}

h3 {
    font-size: 1.75rem;
    font-weight: normal !important;
}

.hidden {
    display: none;
}

button[type=submit] {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: var(--steel-blue); */
    outline: none;
    border: none;
    border-radius: 2rem;
    font-size: 1rem;
    /* height: 3rem;
    width: 8rem; */
    padding: 0.5rem 2rem 0.5rem 2rem !important;
    /* padding: 0 !important; */
    /* color: white !important; */
}

.text-white {
    color: white !important;
}

.text-adj {
    overflow: hidden;
    text-overflow: ellipsis;
}

.cursor-pointer {
    cursor: pointer;
}

.theme-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--steel-blue);
    outline: none;
    border: none;
    border-radius: 2rem;
    font-size: 1rem;
    height: 3rem;
    color: white;
    /* width: 8rem; */
}

.theme-button:disabled {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--steel-blue);
    opacity: 0.5;
    cursor: not-allowed;
    outline: none;
    border: none;
    border-radius: 2rem;
    font-size: 1rem;
    height: 3rem;
    color: white;
    /* width: 8rem; */
}


button {
    cursor: pointer;
    display: flex;
    align-items: center;
    /* padding: .8rem 2rem .8rem 2rem; */
    justify-content: center;
    background-color: var(--steel-blue);
    color: white;
    outline: none;
    border: none;
    border-radius: 2rem;
    font-size: 1rem;
}

.border {
    border: 2px solid var(--border-color);
    border-radius: 0.625rem;
    background-color: white;
    margin-top: 0.5rem;
    padding: 1rem;
}

.table-edit-one {
    display: flex !important;
    align-items: center;
}

.table-edit-one img {
    margin-right: .5rem;
}

.tooltip {
    margin: 0 .5rem;
}

label {
    color: #707070;
    font-size: 0.875rem;

}

img {
    cursor: pointer;
    object-fit: contain !important;
}

select {
    width: 100%;
    padding: .5rem;
    display: block;
    outline: none;
    margin: 0.2rem 0;
    background-color: white;
    border: 1px solid var(--argent);
    border-radius: 0.25rem;
    box-sizing: border-box;
    /* text-transform: capitalize; */
    font-size: 1rem;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url('./assets/images/Path\ -12.png');
    background-repeat: no-repeat;
    background-size: .75rem;
    background-position: calc(100% - 3px);
}

.home-cancel {
    background-color: #fff;
    border: 1px solid #C4C0C0;
    border-radius: 2rem;
    padding: 0.5rem 3rem 0.5rem 3rem;
    outline: none;
}

.home-save {
    color: white !important;
    margin-left: 1rem !important;
}

.home-input-field-file {
    width: 100%;
    padding: .8rem;
    border: 1px solid #D8D8D8;
    border-radius: 0.25rem;
    outline: none;
    margin-top: .3rem;
    font-size: 1rem;
    outline: none;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url('./assets/images/Group\ -41.png');
    background-repeat: no-repeat;
    background-size: 6.5rem;
    background-position: calc(100% - 3px);
    cursor: pointer;
    height: 3rem;
}

.align-items-center {
    align-items: center !important;
}

::file-selector-button {
    display: none;
}

.file-input {
    width: 60%;
    padding: .6rem;
    border: 1px solid var(--argent);
    border-radius: 0.25rem;
    outline: none;
    margin-top: .5rem;
    font-size: 1rem;
    outline: none;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url('./assets/images/Group\ -41.png');
    background-repeat: no-repeat;
    background-size: 7rem;
    background-position: calc(100% - 3px);
    cursor: pointer;
}

/* input:disabled {
  background-color: #e8e5e5;
} */

.input-disabled {
    background-color: #e8e5e5;
}

::file-selector-button {
    display: none;

}

.filter-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

.filter-field p {
    /* margin-left: 1.5rem; */
    color: #1A1919;
    font-size: 0.9rem;
    width: 20%;
}

.filter-search {
    display: flex;
    align-items: center;
    width: 70%;
}

.filter-search-field {
    margin: 1.3rem auto;
    width: 50%;
    border: 1px solid var(--argent);
    height: 2.5rem;
    background-color: #fff;
    border-radius: 0.2rem;
    display: flex;
    overflow: hidden;
    align-items: center;
    margin-right: unset;
    /* box-shadow:0 0.5rem 1.5rem rgba(0, 0, 0, 0.1); */
}

.filter-search-field input {
    width: 100%;
    /* height: 100%; */
    background: none;
    border: none;
    outline: none;
    padding: .8rem 1rem 0.8rem 1rem;
    font-size: 1rem;
    /* color: var(--argent); */
    /* padding: 0.5rem 1rem 0.5rem 1rem; */
    text-transform: none;
}

.filter-search-field label {
    font-size: 1.5rem;
    padding-right: 1rem;
    color: var(--argent);
    /* font-weight: normal; */
    cursor: pointer;
}

.filter-button {
    /* width: 10rem; */
    margin-left: 1rem;
}

.filter-button button {
    border: 1px solid var(--picton-blue);
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    padding-top: .4rem;
    border-radius: 2rem;
    padding-bottom: .4rem;
    background-color: white;
    cursor: pointer;
    color: var(--picton-blue);
    font-size: 1rem;
}

.date {
    width: 100%;
    margin-right: 1rem;
}

.date input {
    font-size: 1rem;
    width: 100%;
    background-color: white;
    color: #C4C0C0;
    border-radius: .25rem;
    padding: .5rem 1rem 0.5rem 1rem;
    border: 2px solid var(--border-color);
}

.password-input-parent {
    position: relative;
}

.password-input-child {
    position: absolute;
    top: 1.9rem;
    right: 1rem;
}

/* UTIL CLASSES */
.text-capitalize {
    text-transform: capitalize;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-light {
    color: #989898;
}

.not-selectable {
    user-select: none;
}

.width-full {
    width: 100% !important;
}

.rs-picker-menu {
    z-index: 2000 !important;
}

input:disabled {
    opacity: 0.6;
}

.mt-1 {
    margin-top: .5rem;
}

.mt-2 {
    margin-top: 2rem;
}

.mt-3 {
    margin-top: 3rem;
}

.mt-4 {
    margin-top: 4rem;
}

.mb-1 {
    margin-bottom: .5rem;
}

.mb-2 {
    margin-bottom: 2rem;
}

.mb-3 {
    margin-bottom: 3rem;
}

.mb-4 {
    margin-bottom: 4rem;
}

.my-2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.not-resizable-x {
    resize: vertical;
}

.not-resizable-y {
    resize: horizontal;
}

.d-flex {
    display: flex;
}

/* UTIL CLASSES */


@media (max-width:720px) {
    .filter-field {
        flex-direction: column;
    }

    .filter-field p {
        margin: 0;
    }

    .filter-search {
        flex-direction: column;
        margin-left: 1.6rem;
    }

    .filter-search-field input {
        /* padding: 0; */
        width: 100%;
    }

    .filter-button {
        width: 100%;
        margin: 0;
    }

    .filter-button button {
        width: 100%;
    }
}

@media (max-width:768px) {
    section {
        flex-direction: column;
    }
}