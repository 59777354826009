.service-container {
  margin-top: 2rem;
}

.service-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.service-body-container {
  border: 2px solid var(--border-color);
  border-radius: 0.625rem;
  background-color: white;
  margin-top: 1rem;
}

.special-body {
  display: flex;
  flex-wrap: wrap;
}

.special-body-para {
  flex: 1 1 200px;
  padding: 0.5rem;
}

.special-body-para p {
  color: #989898;
  font-size: .75rem;
}

.service-arrow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1rem;
}

.special-body-para h4 {
  color: #1A1919;
  font-size: 1.25rem;
}

.service-body-card {
  /* display: flex; */
}

.service-body-content {
  flex: 0 600px;
  border-right: 1px solid var(--border-color);
  padding: 1rem;
  cursor: pointer;
  border-bottom: 2px solid var(--border-color);
}

.service-medical {
  /* margin-left: 1rem; */
  flex: 0 600px;
  padding: 1rem;
  border-bottom: 2px solid var(--border-color);
  cursor: pointer;
}

.service-cat {
  display: flex;
  align-items: center;
}

.service-cat button {
  display: flex;
  margin-left: 1rem;
  background-color: #EAF8FF;
  color: var(--picton-blue);
  border: none;
  outline: none;
  padding: 0.5rem;
  border-radius: 2rem;
  cursor: pointer;
}

.service-btn {
  display: flex;
}

.service-active {
  border-bottom: 2px solid var(--steel-blue);
}

.tab-service {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.service-special-cat {
  padding: 0.9rem 1.5rem;
}

.apt-btn {
  background-color: white;
  border: 1px solid #E3E3E3;
  border-radius: 0.313rem;
  padding: .5rem 2rem .5rem 2rem;
  color: #989898;
}

.btn-active {
  background-color: var(--steel-blue);
  color: white;
  transition: 0.4s linear;
}

@media (max-width:768px) {
  .service-body-card {
    /* flex-direction: column; */
  }

  .service-cat {
    flex-direction: column;
  }

  .service-cat button {
    /* flex-direction: column; */
    margin-left: 0rem;

  }

  .service-btn {
    flex-direction: column;
  }
}