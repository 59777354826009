.push-container {
    width: 80%;
    margin: 6rem auto;

}

.push-container p {
    font-weight: 600;
    font-size: 1rem;
    color: #565664;
}

.form {
    border: 2px solid var(--border-color);
    border-radius: 0.625rem;
    background-color: white;
    margin-top: 0.5rem;
}

.form-body {
    padding: 1rem;
}

.push-input-content {
    display: flex;
    flex-direction: column;
    /*    margin-bottom: .5rem;*/
}

.push-input-content:first-child input {
    padding: .5rem !important;
}

.push-input-content input {
    width: 80%;
    padding: 1rem;
    border: 1px solid #D8D8D8;
    border-radius: 0.25rem;
    outline: none;
    color: #C4C0C0;
    font-size: 1rem;
}

.push-input-content-1 {
    margin-top: 2rem;
}

.push-input-content-1 input {
    width: 40%;
    padding: 1rem;
    outline: none;
    color: #C4C0C0;
    font-size: 1rem;
    border-radius: 0.25rem;
    border: 1px solid #D8D8D8;
}

.push-btn-container {
    margin-top: 1rem;
    display: flex;
    align-items: center;
}

.push-btn-container span {
    border: none;
    background-color: #F7F5F5;
    padding: 0.5rem;
    margin: 0 1rem 0 0rem;
    display: flex;
    align-items: center;
    color: #1A1919;
    font-size: 1rem;
}

.form-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 2px solid var(--border-color);
    margin-top: 1rem;
    padding: 1rem;
    column-gap: 1rem;
}

.push-input-content input {
    padding: .3rem .5rem !important;
}