.apt-container{
    margin-top: 1rem;
}

.review-para {
    font-size: 1rem;
    color: #393939;
    font-weight: 600;
    margin-top: 1rem;
}
.review-apt-container{
padding: 1.5rem;
width: 100%;
margin-top: 4rem;
}
.review-body-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 1rem;
    padding-top: .5rem;
}
.review-body-content p{
    color: #989898;
    font-size: 0.75rem;
}
.review-body-content h4{
    font-size: 0.9rem;
    color: #1A1919;
    font-weight: normal;
}
.doc-review-details{
    width: 25%;
}
.hover{
    font-size: .75rem;
    color: var(--steel-blue);
    position: absolute;
    display: block;
    bottom: 13.2rem;
    right: 9rem;
}
