.container{
    border: 2px solid var(--border-color);
    border-radius: 0.625rem;
    background-color: white;
    margin-top: 0.5rem;
}
.card-body{
    padding: 1rem;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.card-body p{
    font-size: 1rem;
}
.card-body h4{
    font-size: 1.25rem;
    font-weight: normal;
}



.apt-body-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.apt-body-content p{
color: #989898;
font-size: 0.875rem;
}
.apt-table{
    margin-top: 1rem;
}
.apt-para{
    border-left: 1px solid var(--border-color);
  flex: 0 0 160px;
 
}
.apt-para-head{
    margin-left: 1rem;
}

@media (max-width:768px) {
    .apt-body-content{
        /* flex-direction: column; */
        flex-wrap: wrap;
    }
}