.login {
    background-color: #fafafa;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    object-fit: cover;
    object-position: center;
    padding: 2.5rem;
    width: 100%;
    height: 100vh;
}

.login-box {
    width: 80%;
    margin: 0 auto;
}

.login-container {
    background-color: #ffffff;
    width: 70%;
    border: 1px solid var(--border-color);
    margin: 0 auto;
    border-radius: .5rem;
    padding: 2rem 4rem 2rem 4rem;
}

.login-img {
    text-align: center;
}

.login-form {
    width: 50%;
    margin: auto;
    text-align: center;
}

.login-form-heading {
    font-size: 1.75rem;
    margin-top: 1rem;
}

.login-input-field input {
    padding: .8rem 1rem;
    margin-top: 1rem;
    border-radius: 4px;
    border: 1px solid #c4c0c0;
    outline: none;
    font-size: 1rem;
    width: 100%;
}

.login-btn-comp{
    display: flex !important;
    justify-content: center;
}

.login-btn-comp img {
    transform: rotate(-90deg);
    height: 40%;
    /* width: 20%; */
}

.login-btn {
    background-color: #2f77b6 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 16rem !important;
    border: none !important;
    outline: none !important;
    border-radius: 2rem !important;
    margin: 2rem auto !important;
    padding: 1rem 0 1rem 0 !important;
    color: white !important;
    font-size: 1rem !important;
    cursor: pointer !important;
    font-weight: bold !important;
    width: auto !important;
}

.forgot-pass p {
    margin-top: 1.5rem;
    color: rgb(57, 154, 199);
    font-size: 1rem;
    font-weight: bold;
}

.forgot-pass-link {
    text-decoration: none;
}


@media (max-width: 899.95px){
    .login-container{
        width: 100%;
    }
    .login-form{
        width: 100%;
    }
}
/* 
.radio-field{
display:flex;
align-items: center;
justify-content: center;
}
.doctor-radio{
margin-left: 2rem;
}
@media (max-width:720px) {
.signup{
    padding: 1rem;
}
.sign-container{
    width: 100%;
    padding: 1rem;
    flex-direction: column;
}
.sign-img{
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--argent);
    padding-bottom: .5rem;
}
.sign-form{
    width: 100%;
}
} */