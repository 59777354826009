.configure-container {
    padding: 1rem;
}

.configure-container ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid var(--border-color);
}

.conf-tab {
    font-size: 1rem;
    cursor: pointer;
    color: #989898;
    font-weight: 600;
    text-decoration: none;
}

.conf-active-tab {
    border-bottom: 2px solid var(--steel-blue);
    color: var(--steel-blue);
}