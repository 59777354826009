
.view-container-1{
    background-color: #FCFCFC !important;
   margin-top: 4rem;
}
.overlay{
    background-image: url('../../assets/images/opacityimg.png');
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    background-position: center;
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index:30;
}
.view{
    background-color: white;
    padding: 1rem 2rem 4rem 2rem;
   position: relative;
z-index:29;
    margin-top: 1rem;
    /* margin-bottom: 8rem; */
}
.view h3{
 font-size: 1rem;
}
.back-btn{
    border: none;
    color: var( --tufts-blue);
    background-color: transparent;
    cursor: pointer;
font-size: 1rem;
}
.view-meet{
    background-color: #D2E9FD;
    border: 1px solid var(--picton-blue);
    padding: 1rem;
    border-radius: .6rem;
    margin-top: 1rem;
}
.view-meet-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.view-user-meet{
    display: flex;
    align-items: center;
}
.user-meet-content{
    margin-left: 1rem;
    margin-right: 1rem;
}
.user-meet-content h4{
  color: var(--steel-blue);
  font-size: 1rem;
}
.meet-btn button{
    background-color: var(--steel-blue);
    border: none;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: .8rem;
    padding-bottom: .8rem;
    border-radius: 2rem;
    color: white;
    font-size: 1rem;
    cursor: pointer;
}

.view-app-detail{
    border: 1px solid var(--border-color);
    margin-top: 1rem;
    background-color: white;
    padding: 1rem;
    border-radius: .25rem;
}
.view-patient-deatail{
    border-top: 1px solid var(--border-color);
    margin-top: 1rem;
}
.view-patient-deatail h3{
  margin-top: 1rem;
}





.view-doc-detail{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.doc-content{
    width: 50%;
}
.doc-content p{
color: var(--argent);
font-size: 0.75rem;

}

.cancel-btn{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid var(--border-color);
    margin-top: 3rem;
    border-radius: 2rem;
    /* padding: 0; */
}
.cancel-btn .c-btn{
    border: 1px solid var(--steel-blue);
    border-radius: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    background-color: white;
    color: var(--steel-blue);
    font-size: 1rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-top: 1rem;
    /* margin-left: 1rem; */
    margin-right: 1rem;
}
.reject-btn{
    background-color: #E80707;
    border-radius: 2rem;
    /* padding-left: 2rem; */
    /* padding-right: 2rem; */
    color: #989898;
    font-size: 1rem;
    /* padding-top: .5rem; */
    /* padding-bottom: .5rem; */
    display: flex;
    cursor: pointer;
    align-items: center;
    color: white;
    /* margin-top: 1rem; */
    /* margin-left: 1rem; */
    /* margin-right: 1rem; */
}
.fa-circle-xmark{
    margin-right: .5rem;
}
.cancel-btn  .r-btn{
    background-color: var(--steel-blue);
    border: none;
   padding: 0.7rem 2rem 0.7rem 2rem;
   display: flex;
   align-items: center;
    border-radius: 2rem;
    color: white;
    font-size: 1rem;
   margin:1rem 1rem 0rem 1rem;
    cursor: pointer;
   
}
.r-btn-1{
    background-color: transparent;
    border: 1px solid var(--steel-blue);
   padding: 0.7rem 2rem 0.7rem 2rem;
   display: flex;
   align-items: center;
    border-radius: 2rem;
    color: var(--steel-blue);
    font-size: 1rem;
   margin:1rem 1rem 0rem 1rem;
    cursor: pointer;
}
.upload-file-wet{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.down-view{
    /* border: 1px solid var(--argent); */
    margin-top: .5rem;
    padding: 1rem 2rem 2rem 2rem;
    background-color: #FCFCFC;
    background-repeat: no-repeat;
}
.down-view-content{
    border: 1px solid var(--border-color);
    border-top-right-radius: .5rem;
    border-top-left-radius: .5rem;
    background-color: white;
}
.download-view-content{
    display: flex;
    justify-content:space-between;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    /* margin-top: 1rem; */
    padding: 1rem;
   
    /* margin-bottom: rem; */
}

.virtual-1{
    margin-left: 10rem;
}
.download-view-content h3{
    font-size: 1.5rem;
    font-weight: normal;
    color: #565664;
   
}
.down-img-btn{
    cursor: pointer;
   
}
.down-details{
    padding: 1rem;
}
.down-details-border1{
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 1rem;
    padding-top: .2rem;
}
.down-details-border2{
    padding-top: .2rem;
}
.download-clinic{
    border-top: 1px solid var(--border-color);
    padding: 1rem;
 
    /* margin: 1rem; */
}
.download-clinic p{
color: #707070;
font-size: .875rem;
}
.down-rec{
    border: 1px solid #13BC34;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 1rem;
    border-radius: .8rem;
}
.down-rec h4{
 color: #13BC34;
}
.down-footer-container{
    background-color: #FCFCFC;
    background-repeat: no-repeat;
}
.down-view-footer{
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
    border: 1px solid #F0F0F0;
    border-radius: .25rem;
    background-color: white;
    padding: 1rem 1rem 3rem 1rem;
  
}
.down-view-footer-content{
    width: 40%;
}
.down-view-body{
    margin-top: 1rem;
}
.down-view-body p{
font-size: .75rem;
color: var(--argent);
}
.down-view-body h4{
color: #1A1919;
font-size: 0.938rem;
font-weight: normal;
}


@media (max-width:720px) {
    .view{
        padding: 0.5rem;
    }
    .view-meet-content{
        flex-direction: column;
    }
    .meet-btn button{
       padding: .5rem;
       margin: 0;
       width: 100%;
    }
  
    
    .view-doc-detail{
        flex-direction: column;
    }
    .doc-content{
        width: 100%;
    }
   
    .cancel-btn{
        flex-direction: column;
    }
    .cancel-btn  .r-btn{
        width: 85%;
        margin-left: 0;
    }
}

.common-card {
    display: flex;
    align-items: center;
    margin-top: 1rem;

    /* justify-content: space-between; */
}

.common-card p {
    color: var(--argent);
    font-size: 0.75rem;
}

.common-card h4 {
    font-size: 0.938rem;
    font-weight: normal;
}

.common-body1 {
    margin-left: 4rem;
}

.view-attach {
    /* margin-left: 5rem; */
    width: 40%;
}

.document-img-content {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    justify-content: space-between;
}

.doc-img-border {
    width: 95%;
}

.doc-img-border img {
    width: 100%;
    height: 200px;
    border-radius: 1rem;
}

.view-attach p {
    color: var(--argent);
 
    font-size: 0.75rem;
}

.viewdicom {
    display: flex;

    align-items: center;
    /* justify-content: space-between; */
}

.viewdicom button {
    border: 1px solid var(--steel-blue);
    border-radius: 2rem;
    padding: 0.5rem 1rem 0.5rem 1rem;
    background-color: white;
    color: var(--steel-blue);
    font-size: 1rem;
    display: flex;
    cursor: pointer;
    margin: 1rem;
    align-items: center;

}

.fa-arrow-right {
    margin-left: .5rem;
}

.fa-print {
    margin-right: .5rem;
}


.view-container {
    border: 1px solid var(--border-color);
    border-radius: .5rem;
    margin-top: 1rem;
}

.view-logo {
    background-color: #ECF1FA;
    /* margin-top: 1rem; */
    padding: .4rem;
    /* display: flex;
    justify-content: center; */
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
 text-align: center;
}

.view-logo img {
    width: 6rem;
}

.view-content {
    padding: 1rem;
    border-bottom: 1px solid var(--border-color);
}

.view-heading {
    font-size: 1rem;
    color: #565664;
    font-weight: 600;
}


.clinic-container {
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid var(--border-color);
    /* align-items: center; */
    /* padding: 1rem; */
}

.clinic-container p {
    font-size: 0.9rem;
    margin-top: .5rem;
}

.clinic-data {
    width: 30%;
    margin-left: 1rem;
    padding: 1rem;
}

.breif-content {
    width: 30%;
    padding: 1rem;
    margin-left: 5.5rem;
    border-left: 1px solid var(--border-color);
    border-right: 1px solid var(--border-color);
}

.recommend-content {
    width: 30%;
    padding: 1rem;
    margin: 1rem;
    border: 1px solid #13BC34;
    border-radius: .8rem;
}

.recommend-content h3 {
    color: #13BC34;
    font-size: 1rem;
}

.attach-content {
    padding: 1rem;
}

.attach-btn {
    display: flex;
    align-items: center;
}

.pdf-btn-1 {
    padding: .5rem;
    background-color: white;
    border: 1px solid var(--border-color);
    border-radius: .5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 1rem;
}

.attach-btn .pdf-btn {
    padding: .5rem;
    background-color: white;
    border: 1px solid var(--border-color);
    border-radius: .5rem;
    align-items: center;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 1rem;
}

.view-btn {
    border: 1px solid var(--argent);
    background-color: var(--steel-blue);
    color: white;
    margin-top: 1rem;
    margin-left: 1rem;
    padding: .5rem 1rem .5rem 1rem;
    border-radius: .25rem;
    font-size: 1rem;
    align-items: center;
    /* display: flex; */
}
/* .view-btn img {
    color: white !important;
} */
.dwn-btn {
    border: 1px solid var(--argent);
    background-color: var(--steel-blue);
    color: white;
    margin-top: 1rem;
    margin-left: 1rem;
    padding: .5rem 1rem .5rem 1rem;
    border-radius: .25rem;
    font-size: 1rem;
    align-items: center;
}

.file-view-body {
    width: 30%;
}

.file-view-body-1 {
    width: 30%;
}

.d-file {
    width: 1rem;
    margin-right: .5rem;
    margin-left: .5rem;
}

.attach-btn button a {
    margin-left: 3rem;
    margin-right: 3rem;
}

.details-btn {
    margin-left: 2rem;
    margin-right: 2rem;
}


/* responsive  */
@media (max-width:720px) {
    .view-app-deatails p {
        text-align: left;
    }

    .common-card {
        flex-direction: column;
        text-align: center;
        /* justify-content: center; */
    }

    .common-body1 {
        margin: 0;
    }

    .view-attach {
        width: 100%;
        margin: 0;
    }

    .viewdicom {
        flex-direction: column;
    }

    .viewdicom button {

        flex-direction: column;
        font-size: .8rem;
        margin-top: 1rem;
    }
    
    .print-btn {
        margin: 0;
        margin-left: 8rem;
        margin-top: 1rem;
        width: 72%;
        /* width: 100%; */
    }

    .clinic-container {
        flex-direction: column;
    }

    .clinic-data {
        width: 100%;
        margin: 0;
    }

    .breif-content {
        width: 100%;
        margin: 0;
        border: none;
        border-top: 1px solid var(--argent);
        border-bottom: 1px solid var(--argent);
    }

    .recommend-content {
        width: 100%;
        margin: 0;
        padding: 1rem;

    }

    .attach-btn {
        flex-direction: column;
    }

    .attach-btn button {
        /* padding: .5rem; */
        flex-direction: column;
    }

    .attach-btn button a {
        margin: 0;
    }

    .details-btn {
        margin: 0;
        margin-top: 1rem;
    }

    .down-view {
        margin: 1rem;
    }

    .download-view-content {
        flex-direction: column;
        justify-content: center;
    }
}