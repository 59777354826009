.invoice-download-container {
    width: 80%;
    margin: 10rem auto;
}

.earn-body-content {
    border: 2px solid var(--border-color);
    border-radius: .625rem;
    background-color: white;
}

.earn-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem;
    border-bottom: 1px solid var(--border-color);
}

.earn-head p {
    font-weight: 600;
    font-size: 1rem;
}

.earn-btn-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
}

.total-earning-content {
    display: flex;
    margin: 1rem;
    justify-content: space-between;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--border-color);
}

.total-date-select {
    width: 25%;
}

.total-earning-content input {
    width: 100%;
    padding: .6rem;
    border: 1px solid #D8D8D8;
    background-color: white;
    border-radius: 0.25rem;
}

.total-earn-body {
    display: flex;
    align-items: center;
}

.total-earn-body p {
    color: #989898;
    font-size: 14px;
}

.total-earn-card {
    margin-right: 3rem;
    border-right: 1px solid var(--border-color);
}

.earn-table {
    padding: 1rem;
}