.user-appointment-container{
    display: flex;
    margin-top: 1rem;
    justify-content: space-between;
}
.user-app-content {
    border: 1px solid var(--argent);
    background-color: white;
    border-radius: 0.6rem;
    width: 35%;
  }
  .user-app-content-2 {
    border: 1px solid var(--argent);
    background-color: white;
    border-radius: 0.6rem;
    width: 62%;
  }
  .user-app-body {
    /* width: 47%; */
  }
  .user-upcoming {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--argent);
    padding: 0.8rem;
  }
  .user-upcoming h5 {
    font-size: 1rem;
  }
  .user-upcoming h5 span {
    color: var(--argent);
    font-size: 0.75rem;
    margin-left: 0.2rem;
  }
  .appointment-arrow{
    margin-right: 1rem;
  }
  .user-up-content {
    display: flex;
  }
  .user-up-body {
    border: 1px solid var(--argent);
    margin:.2rem;
    border-radius: 0.6rem;
  }
  .user-upcoming-btn {
    background-color: #d5f1ff;
    padding: 0.2rem;
    width: 6.5rem;
    border: none;
    font-weight: 700;
    color: var(--picton-blue);
    border-top-left-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
  }
  .user-inreview-btn {
    background-color: #fff4e8;
    border: none;
    color: #f4c548;
    display: flex;
    padding: 0.2rem;
    width: 6.5rem;
    font-size: 0.85rem;
    font-weight: 600;
    border-top-left-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
  }
  .user-app-id {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    flex-wrap: wrap;
    border-bottom: 1px solid var(--argent);
  }
  .user-app-id p {
    color: #989898;
    font-size: 0.75rem;
  }
  .user-app-id h4 {
    color: var(--eerie-black);
    font-size: 0.75rem;
  }
  .user-app-body {
    width: 50%;
    margin-top: 1rem;
  }
  .user-gender {
    display: flex;
    width: 50%;
    align-items: center;
    margin-top: 1rem;
  }
  .user-age {
    margin-left: 3rem;
  }
  .user-up-btn {
    display: flex;
    justify-content: space-around;
  }
  .user-cancel {
    border-left: 1px solid var(--argent);
  }
  .user-attend {
    color: var(--steel-blue);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .user-past-content {
    border: 1px solid var(--argent);
    margin-top: 2rem;
    background-color: white;
    border-radius: 0.5rem;
    
  }
  .user-past {
    border-bottom: 1px solid var(--argent);
    padding: 1rem;
  }
  .user-table-past{
    padding: 1rem;
  }
 
  @media (max-width:720px) {
    .user-app-content-2{
      margin-top: 1rem;
    }
     .user-upcoming {
   flex-direction: column;
    }
    .appointment-arrow{
      margin-top: 1rem;
    }
    
  }