.d-container {
    width: 95%;
    margin: 6rem auto;
}

.doctor-approvel-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.625rem;
    padding: 1.5rem;
    border: 1px solid #EFD511;
    background-color: #FFFDF2;
}

.doctor-approvel-container-accepted {
    background-color: #81c78442;
    border-color: #81c784;
}

.doctor-approvel-container-rejected {
    background-color: #e5737314;
    border-color: #e57373;
}

.approvel-body {
    width: 60%;
    display: flex;
    align-items: center;
}

.approvel-para {
    margin-left: 1rem;
}

.approvel-para h4 {
    font-size: 1rem;
    color: #1A1919;
    font-weight: 600;
}

.approvel-para p {
    font-size: .9rem;
    color: #989898;
}

.approvel-btn-container {
    width: 38%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.reject-btn {
    display: flex;
    width: 11rem;
    background-color: #E80707;
    color: white;
    margin-right: 1.5rem;
    padding: 0.6rem 2rem 0.6rem 2rem;
}

.approve-btn {
    display: flex;
    border-radius: 2rem;
    width: 11rem;
    background-color: #13BC34;
    color: white;
    padding: 0.6rem 2rem 0.6rem 2rem;
}

.doctor-tab-container {
    margin-top: 1rem;
}

.doctor-tab-container ul {
    display: flex;
    list-style: none;
    border-bottom: 2px solid var(--border-color);
}

.doctor-tab-container ul a {
    text-decoration: none;
}

.doctor-tab-container ul .active {
    border-bottom: 2px solid var(--steel-blue);
    color: var(--steel-blue);
}

.doctor-list-tab {
    padding: 0 1rem;
    margin: 0 .2rem;
    font-size: 1rem;
    cursor: pointer;
    color: #989898;
    font-weight: 600;
}

.doctor-list-tab-1 {
    font-size: 1rem;
    cursor: pointer;
    color: #989898;
    font-weight: 600;
    margin-left: 4rem;

}

.doctor-list-active-tab {
    border-bottom: 2px solid var(--steel-blue);
    color: var(--steel-blue);
}