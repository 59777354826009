.edit-policy-container{
    width:80%;
    margin: 8rem auto;
}
.policy-wrapper{
    border: 2px solid var(--border-color);
    border-radius: 00.625rem;
    background-color: white;
}
.policy-form{
    display: flex;
    align-items: center;
    border-bottom: 2px solid var(--border-color);
    padding: 1rem;
}
.policy-input-content{
    width: 40%;
}
.policy-input-field{
    width: 100%;
    padding: .8rem;
    border: 1px solid #D8D8D8;
    border-radius: 0.25rem;
    outline: none;
    margin-top: .3rem;
    font-size: 1rem;
}
.policy-section-one{
    padding: 1rem;
    border-bottom: 1px solid var(--border-color);
}
.section-one-input{
    width: 82.7%;
    margin-top: 1rem;
    padding: 1rem initial;

}
.section-bg-input{
    width: 40%;
    margin-top: 1rem;
}
.title-input-container{
    display: flex;
    align-items: center;
    margin-top: 1rem;
    
}
.file-input{
    display: flex;
    align-items: center;
    
}
.delete-icon{
    color: var(--steel-blue);
    width: 2rem;
    height: 2rem;
    margin-left: 1rem;
    cursor: pointer;
}

 .policy-btn-content{
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    box-shadow: 0 0.1rem .5rem rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 1rem;
bottom: 0;
    left: 0;
 }
 
 .policy-mark{
    display: flex;
    align-items: center;
 }
 .policy-preview{
    background-color: white;
    color: var(--steel-blue);
    border: 1px solid var(--steel-blue);
    outline: none;
    padding: 0.5rem 3rem 0.5rem 3rem;
    margin-right: 1rem;
 }
 .upload-btn-wrapper {
  position: relative; 
  overflow: hidden; 
  display: block; 
  text-align: right;
}
 .uploadBtn {
background: #FFFFFF; 
color: #2F77B6; 
outline: none; 
text-align: center; 
font-size: 13px;
padding: 3px 15px; 
border: 1px solid #2F77B6; 
font-weight: 600; border-radius: 22px
}
.upload-btn-wrapper input
[type=file] {
font-size: 100px; 
position: absolute; 
left: -124px; 
top: 0; 
opacity: 0; 
cursor: pointer; 
height: 500px;
font-size: 20px; 
width: 900px;
}