.user-over-view{
    width: 68%;
}
.user-over-view p{
  font-size: 0.875rem;
}
.over-view-container{
    display: flex;
    margin-top: 0.5rem;
  
}
.over-view-body-1{
  flex-grow: 1;
}
.over-view-container p{
  color: #989898;
  font-size: 0.875rem;
}
 
.over-view-body{
    flex-grow: 1;
    border-left: 2px solid var(--border-color);
}
.mobile-app{
  margin-top: 2rem;
}
.appointment-sec{
    width: 30%;
}
.appointment-sec p{
  font-size: 0.875rem;
  color: #1A1919;
}
.generate-invoice{
  margin-top: 2rem;
}


@media (max-width:768px) {
  .user-over-view{
    width: 100%;
  }
  .appointment-sec{
    width: 100%;
  }
}