.payment-total{
    margin-top: 1rem;
}

.payment-total-body{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.payment-total-body p{
color: #989898;
font-size: 0.875rem;
}
.payment-table{
    margin-top: 1rem;
}
.payment-para{
    border-left: 1px solid var(--border-color);
  flex: 0 0 300px;
 
}
.payment-para-head{
    margin-left: 1rem;
}
@media (max-width:768px) {
    .payment-total-body{
        flex-wrap: wrap;
    }
}