.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 2rem 2rem 1.8rem;
    width: 100%;
    height: 5rem;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
  }
  
  .header .lang-select {
    border: 1px solid #f4f4f4;
    background-color: white;
    padding: 0.5rem 0rem;
    margin-left: 1rem;
    border-radius: 0.2rem;
  }
  .logo-header {
    display: flex;
    justify-items: center;
    align-items: center;
  }
  .header .logo {
   
    width: 120px;
    max-height: 100%;
  }
  
  #menu-btn {
    display: none;
    height: 2.5rem;
    width: 3.5rem;
    line-height: 2.5rem;
    border-radius: 0.5rem;
    background-color: #eee;
    color: black;
    font-size: 1.5rem;
    margin-left: 1.6rem;
    cursor: pointer;
    text-align: center;
  }
  
  #close-btn {
    display: none;
    height: 2.5rem;
    width: 3.5rem;
    line-height: 2.5rem;
    border-radius: 0.5rem;
    background-color: #eee;
    color: black;
    font-size: 1.5rem;
    margin-left: 1.6rem;
    cursor: pointer;
    text-align: center;
  }

  .box-one {
    width: 70% !important;
  }
  .box-one p {
    font-size: 14px !important;
  }
  
  
  
  .dropbtn {
    background-color: white;
    width: 8.125rem;
    padding: 4px;
    margin-left: 2rem;
    border:1px solid #eee;
    border-radius: .25rem;
    font-size: .875rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 8px 16px 0px rgba(255,255, 255, 0.7);
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
    margin-right: 1rem;
  }
  .translate {
    width: 24px;
    margin: 5px;
  }
  .chevronDown{
    width: 16px;;
    margin: 8px;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    border-radius:8px;
    left: 2.063rem;
    /* right: 1rem; */
    width: 8.125rem;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  
  .dropdown-content span{
    color: black;
    cursor: pointer;
    padding: 10px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content span:hover {
    background-color: #f1f1f1;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
    /* padding: 0 1rem  0; */
  }
  
  .dropdown:hover .dropbtn {
    background-color: white;
  }
  .message{
    border-left: 2px solid var(--border-color);
    border-right: 2px solid var(--border-color);
    margin: 0 1rem 0 1rem;
  }
  .message-icon{
    padding: 1rem;
    margin: 0 1.5rem 0 1.5rem;
  }
  .bell-icon{
    padding: 1rem;
    margin: 0 1.5rem 0 0rem;
  }
  .bell{
    margin: 0 1rem 0 1rem;
    border-right:2px solid var(--border-color);
    
    cursor: pointer;
  }
  .avatar{
    width: 60px;
    margin-left: 25px;
    cursor: pointer;
    height: 60px;
    background-color: red;
    border-radius: 50%;
  }
  .book-list-nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
   
    
  }
  .book-nav-content{
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
  }
  .book-profile-nav{
    display: flex;
    align-items: center;
  }
  .book-nav-para{
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  
  @media (max-width: 991px) {
   
    .header {
      padding: 2rem;
      width: 100%;
    }
  
  }
  @media (max-width: 720px) {
    #menu-btn {
      display: inline-block;
    }
    .header {
      padding: 1rem;
    }
   #close-btn{
     display: block;
   }
   

   .bell{
    margin-top: 1rem;
    margin-left: 0;
   }
   .book-list-nav{
    position: fixed;
    left:100%;
       width:60%;
       height: calc(100vh - 500px);
     background-color: #fff;
    flex-direction: column;
     justify-content: center;
     box-shadow: var(--box-shadow);
   }
   .book-profile-nav{
    flex-direction: column;
    margin-top: 1rem;
   }
   .book-nav-content{
    flex-direction: column;
  }
   .book-is-expended{
    left: 7.2rem;
    transition: 0.4s linear;
    top: 4rem;
   }
    
  }
  
  
  