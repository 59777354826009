.profile {
    display: flex;
    justify-items: space-between;
}

.profile-attend {
    display: flex;
    margin-top: 2rem;
    /* align-items: center; */
}

.profile-attend-body {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid var(--border-color);
    padding: 1rem;
    border-radius: 0.625rem;
    background-color: white;
}

.profile-attend-body p {
    color: #989898;
    font-size: .875rem;
}

.profile-attend-body-1 {
    width: 75%;
    margin-left: 1rem;
    background-color: white;
    border: 2px solid var(--border-color);
    padding: 1rem;
    border-radius: 0.625rem;
}

.profile-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    padding: 1rem;
}

.profile-head p {
    color: #393939;
    font-size: 1rem;
    font-weight: bold;
}

.profile-img {
    text-align: center;
    padding: 2rem 2rem 0rem 2rem;
}

.profile-img .d-image {
    width: 14rem;
    position: relative;
}

.star-img {
    position: absolute;
    left: 2rem;
    top: 3rem;
}

.profile-content {
    width: 75%;
    margin-top: 2rem;

}

.d-profile-heading {
    font-size: 0.875rem !important;
    color: #1a1919;
}

.profile-content h4 {
    /* margin-top: 0.4rem; */
    font-weight: normal;
    color: #1a1919;
    font-size: 0.875rem;
}

.profile-content p {
    color: #989898;
}

.profile-about {
    margin: 1rem 0;
}

.profile-time {
    display: flex;
    /* justify-content: space-between; */
    padding-bottom: 3rem;
    flex-wrap: wrap;
    /* align-items: center; */
}

.time-two {
    margin: 1.5rem 0rem 0rem 10rem;
}

.edit {
    margin-right: 1rem;
    margin-left: 1rem;
    color: var(--steel-blue);
    list-style: none;
    cursor: pointer;
}

.profile-time h5 {
    margin-top: 1rem;
    margin-right: 10rem;
}

.time-m {
    display: flex;
    align-items: center;
}

.m {
    background-color: #ceecff;
    width: 2rem;
    height: 2rem;
    color: #3a9cd8;
    display: flex;
    align-items: center;
    justify-content: center;
    /* text-align: center; */
    border-radius: 50%;
    /* margin-top: 1rem; */
}

.ten {
    margin-left: 0.5rem;
    color: #1a1919 !important;
    font-size: 0.875rem;
}

@media (max-width:720px) {
    .profile {
        flex-direction: column;
    }

    .profile-img .d-image {
        width: 100%;
    }

    .profile-content {
        width: 100%;
        padding: 0.5rem;
    }

    .profile-time {
        flex-direction: column;
    }
}