.user-total {
    margin-top: 1rem;
}

.user-total-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.user-total-body p {
    color: #989898;
    font-size: 0.875rem;
}

.user-table {
    margin-top: 1rem;
}

.user-para {
    border-left: 1px solid var(--border-color);
    flex: 0 0 50px;

}

.user-para-head {
    margin-left: 1rem;
}

.add-users-btn {
    /* margin-right: 6rem; */
    color: white;
    padding: 0 1rem;
}

.user-para-btn {
    position: relative;
}

.btn-drop {
    position: absolute;
    top: 14rem;
    right: 4rem;
    text-align: center;
    /* width: 13rem; */
    display: none;
    background-color: white;
    /* padding: 1rem; */
    border: 1px solid var(--border-color);
}

.btn-drop p {
    /* padding: 1rem 0 1rem 0; */
    padding: .8rem 2.5rem;
    border-bottom: 1px solid var(--border-color);
    cursor: pointer;

}

.active-popup {
    display: block;

}

@media (max-width:768px) {
    .user-total-body {
        flex-wrap: wrap;
    }

    .user-para-btn {
        width: 100%;
    }

    .btn-add {
        width: 100%;
    }

}