.add-coupon{
    display: flex;
    align-items: center;
    width:30%;
    margin-left: 1rem;
}
.add-coupon button{
    padding: 0.5rem 2rem 0.5rem 2rem;
    width: 100%;
    border: 1px solid var(--steel-blue);
    background-color: white;
    color: var(--steel-blue);
  
}
.coupon-container{
    padding: 1rem;
    border-bottom: 2px solid var(--border-color);
}
.create-coupon-form{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.create-input-content{
    width: 40%;
    margin-top: 1rem;
}
.coupon-input-field{
width: 100%;
padding: .8rem;
    border: 1px solid #D8D8D8;
    border-radius: 0.25rem;
    outline: none;
    margin-top: .3rem;
    font-size: 1rem;
}
.coupon-generate-field{
    width: 100%;
    padding: .8rem;
        border: 1px solid #D8D8D8;
        border-radius: 0.25rem;
        outline: none;
        margin-top: .3rem;
        font-size: 1rem;
    
        appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
   
    background-repeat: no-repeat;
    background-size: 1rem;
    background-position: calc(100% - 3px);
}
input[type="date"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    border-radius: 4px;
    margin-right: 2px;
  }
  
  .desc-input-content{
    width: 82.9%;
    margin-top: 1rem;
  }
  .coupon-btn-content{
    display: flex;
    justify-content: center;
    padding: 1rem;
  }