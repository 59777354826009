.doctor-upcoming-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #F0F0F0;
    padding: 1rem;
}

.doctor-upcoming-head p {
    color: #393939;
    font-size: 1rem;
    font-weight: bold;
}

.upcoming-container {
    display: flex;
    justify-content: space-between;
    padding: .5rem 2rem;

}

.upcoming-content {
    border: 1px solid var(--argent);
    width: 30%;
    margin: 1.5rem .5rem;
    border-radius: 0.625rem;
    padding-bottom: 0.8rem;
    background-color: white;
    /* padding-left: .5rem; */
}

.no-upcoming-content {
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.upcoming {
    background-color: #d5f1ff;
    padding: .2rem 0.5rem;
    width: 6.5rem;
    font-weight: 700;
    color: var(--picton-blue);
    border-top-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    /* height: 3rem; */
}


.appointment {
    display: flex;
    align-items: baseline;
    /* justify-content: space-around; */
}

.appointment-content {
    width: 50%;
    padding-left: 0.5rem;
}

.appointment-content p {
    color: #989898;
    font-size: 0.75rem;
    margin-top: 0.5rem;
}

.appointment-id p {
    font-size: 0.75rem;
    width: 100%;
    margin-top: 0.5rem;
    font-weight: bold;
    color: #1A1919;
}

.view-resh-content {
    display: flex;
    justify-items: center;
    justify-content: space-evenly;
    margin-top: 1rem;
}

.view-button {
    border: 2px solid var(--picton-blue);
    background-color: white;
    color: var(--eerie-black);
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    border-radius: 2rem;
    /* margin-left: .3rem; */
    cursor: pointer;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
    /* padding-right: .5rem; */
}

.resch-button {
    border: 2px solid var(--argent);
    background-color: white;
    color: var(--argent);
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    padding-top: .2rem;
    padding-bottom: .2rem;
    border-radius: 2rem;
    margin-left: .3rem;
    margin-right: .3rem;
    cursor: pointer;
}


@media (max-width:720px) {
    .upcoming-container {
        flex-direction: column;
    }

    .upcoming-content {
        width: 100%;
    }
}