
.invoice-body p{
    color: #989898;
    font-size: 0.75rem;
}
.invoice-input-container{
    margin-top: 0.5rem;
}
.invoice-input-container label{
    font-size: 0.875rem;
    color: #707070;
}
.invoice-input-container input{
    width: 100%;
    padding: .8rem;
    border: 1px solid var(--border-color);
    border-radius: 0.25rem;
    margin-top: 0.5rem;
}

