.query-total{
    margin-top: 1rem;
}

.query-total-body{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.query-total-body p{
color: #989898;
font-size: 0.875rem;
}
.bold{
  font-weight: bold;
}
.query-table{
    margin-top: 1rem;
}
.query-para{
    border-left: 1px solid var(--border-color);
  flex: 0 0 200px;
 
}
.query-para-head{
    margin-left: 1rem;
}
.query-open{
  width: 40%;
  /* position: absolute; */
   /* height: 3rem; */
   padding: .5rem 1rem .5rem 1rem ;
  border: 1px solid #16CB0E;
  display: none;
  border-radius: .25rem;
  background-color: #E9FFE8;
}
.query-main{
   /* padding: .5rem 1rem .5rem 1rem ; */
  display: none;
  border-radius: .25rem;
  background-color:white;
  transform: 'translate(-50%, -50%)',
}
.query-flex{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.query-flex-1{
  display: flex;
  align-items: center;
  
  /* justify-contsent: space-between; */
}
.query-flex-1 p{
  color: #1A1919;
  font-size: 0.875rem;
  margin-left: .5rem;
}
.query-para-1{
  color: var(--steel-blue);
  font-size: .875rem;
  border-bottom: 1px solid var(--steel-blue);
  cursor: pointer;
}

.query-active{
  display: block;
  right: 30%;
  position: absolute;
  top: 8rem;
}
.query-main-active{
  display: block;
  right: 30%;
  position: absolute;
  top: 8rem;
}
@media (max-width:768px) {
  .query-total-body{
    flex-wrap: wrap;
  }    
}