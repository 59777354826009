.video-wrapper {
    margin: 1rem;
    padding-bottom: .5rem;
}

.video-main-container {
    border: 1px solid var(--argent);
    margin-top: .5rem;
    border-radius: 0.625rem;
    padding-bottom: .2rem;
}

.video-doctor-details-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

.verified-img-container {
    display: flex;
    align-items: center;
}

.video-heading {
    font-size: 1.25rem;
    color: #272B41;
    font-weight: normal;
}

.span-head {
    font-size: .875rem;
    color: #9395A0;
}

.video-btn-container {
    display: flex;
    align-items: center;

}

.view-btn-video {
    border: 1px solid var(--steel-blue);
    background-color: white;
    border-radius: 2rem;
    display: flex;
    color: var(--steel-blue);
    font-size: 1rem;
    margin: 0 .5rem 0 .5rem;
    padding: .5rem 2rem .5rem 2rem;
}

.end-video-btn {
    border: none;
    background-color: var(--steel-blue);
    border-radius: 2rem;
    display: flex;
    color: white;
    font-size: 1rem;
    margin: 0 .5rem 0 .5rem;
    padding: .6rem 2rem .6rem 2rem;
}

.flex {
    /* padding: 0rem 1rem 1rem 1rem; */
    display: flex;
    height: calc(100vh - 6rem);

}


.video-container {
    margin: 0.25rem;
    /* border: 1px solid #777; */
    flex-grow: 1;
}

.chat-container {
    margin: 0.25rem;
    /* border: 1px solid #777; */
    width: clamp(300px, 30vw, 50vw);
}

.chat-window {
    flex-direction: column;
    display: flex;
    height: 100%;
}

.message-list {
    overflow-y: scroll;
    flex-grow: 1;
}

.chat-btn-container {
    display: flex;
    align-items: center;
    padding: 0.1rem .5rem;
    /* margin: 1rem 0rem 0 1rem; */
}

.chat-btn-container input {
    width: 244px;
    border: 1px solid var(--argent);
    padding: .8rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    outline: none;
}

.chat-send-btn {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.chat-send-btn img {
    /* width: 100%; */
    margin-left: .2rem;
}

.chat-data-head {
    display: flex;
    align-items: center;
}

.doctor-video-para {
    margin-left: 1rem;
    margin-right: 1rem;
    font-size: 0.875;
    color: #9395a0;
}



/* live video css  */
.live-time {
    position: absolute;
    background-color: #E9FFE8;
    border-bottom-right-radius: 1rem;
    padding: .8rem;
}

.live-time p {
    color: #16CB0E;
    font-size: .75rem;
    font-weight: 600;
}

.room {
    position: relative;
}

.room button {
    position: absolute;
    top: 0;
    right: 20px;
}

.room>h3 {
    text-align: center;
    font-weight: 300;
    margin-bottom: 1em;
}

.local-participant {
    text-align: center;
    height: calc(100vh - 6rem);
    margin-bottom: 2rem;
    border-radius: 6px;
    /* display: inline-block; */
    background: transparent linear-gradient(180deg, #2F77B6 0%, #020C15 100%) 0% 0% no-repeat padding-box;
    ;
    /* background: transparent linear-gradient(180deg, var(--steel-blue) 0%, #020C15 100%) 0% 0% no-repeat padding-box; */
}

.remote-participants {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0 2em 2em;
    position: absolute;
    width: 12rem;

    background: none;
    left: .5rem;
    bottom: 2rem;
}

.participant {
    width: 100%;

}

.participant:last-child {
    margin-right: 0;
}

.participant h3 {
    text-align: center;
    padding-bottom: 0.5em;
    color: #fff;
}

video {
    width: 100%;
    display: block;
    max-width: 600px;
    margin: 0 auto;
    /* height: 40rem; */
}

.video-tool-kit {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 6rem;
    right: 24rem;
}

.file-url {
    width: 10rem;
    border: 1px solid var(--argent);
}




@media (max-width:720px) {
    .video-doctor-details-head {
        flex-direction: column;
    }

    .verified-img-container {
        flex-direction: column;
    }

    .video-btn-container {
        flex-direction: column;
    }

    .flex {
        flex-direction: column;
    }
}




/* Chat arrow message */
.arrow_box {
    position: relative;
    background: #88b7d5;
  }
  
  .arrow_box:after {
    right: 100%;
    top: 24.5%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-right-color: #88b7d5;
    /* border-width: 30px; */
    border-width: 0px 30px 30px 0px;
    margin-top: -30px;
  }
  
  
  .right-chat-msg {
    background-color: red;
  }
  
  .left-chat-msg {
    background-color: green;
  
  }
  
  .arrow_box {
    position: relative;
    background: #88b7d5;
    padding: 0.2rem 1rem;
    border-radius: 5px;
    border-top-left-radius: 0px;
  }
  
  .arrow_box:after {
    right: 100%;
    top: 37%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-right-color: #88b7d5;
    /* border-width: 30px;  */
    border-width: 0px 30px 30px 0px;
    margin-top: -30px;
  }
  
  .arrow_box2 {
    position: relative;
    background: gray;
    padding: 0.2rem 1rem;
    border-radius: 5px;
    border-top-left-radius: 0px;
  }
  
  .arrow_box2:after {
    left: 100%;
    /* right: 0px; */
    top: 40.5%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-right-color: gray;
    border-width: 51px 0 0 36px;
    margin-top: -30px;
    z-index: 10;
  }
  
  
  .talk-bubble {
    /* margin: 40px; */
    display: inline-block;
    position: relative;
    width: 100%;
    height: auto;
    background-color: rgb(240, 240, 240);
    
  }
  /* .talktext2{background: yellow !important; color: #000000 !important;} */
  
  .triangle.left-top:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: -20px;
    right: auto;
    top: 0px;
    bottom: auto;
    border: 22px solid;
    border-color: rgb(240, 240, 240) transparent transparent transparent;
  }
  
  .triangle.right-top:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: -20px;
    top: 0;
    bottom: auto;
    border: 32px solid;
     border-color: rgb(240, 240, 240) transparent transparent transparent; 
    /* border-color: yellow; */
  }
  
  .talktext {
    padding: 1em;
  }
  
  
  
  .my-5 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }