.revenue-container {
    margin-top: 1rem;
}

.revenue-total {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.revenue-para p {
    color: #989898;
    font-size: 0.875rem;
}

.revenue-drop {
    display: flex;
    width: 25rem;
}

.revenue-drop select {
    width: 100%;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border: 1px solid var(--border-color);
    border-radius: 0.25rem;
    background-color: white;

    outline: none;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url('../../../assets/images/chevron-down.png');
    background-repeat: no-repeat;
    background-size: .75rem;
    background-position: calc(100% - 3px);
}

.revenue-service {
    width: 60%;
}

.calender {
    width: 35%;
    margin-left: 1rem;
}

@media (max-width:768px) {
    .revenue-total {
        flex-direction: column;
    }

    .revenue-drop {
        flex-direction: column;
    }

    .revenue-service {
        width: 100%;
    }

    .calender {
        width: 100%;
        margin-left: 0;
    }

    .revenue-drop select {
        width: 100%;
        margin-top: 1rem;
    }
}