.general-container {
    margin-top: 1rem;
}

.general-input-body {
    display: flex;
    /* justify-content: space-between; */
}

.general-input {
    width: 50%;
    display: flex;
    /* justify-content: center;
    align-items: center; */
    flex-direction: column;
}

.general-input input {
    height: 3rem;
    width: 100%;
    padding: 1rem;
    background-color: white;
    border: 2px solid #D8D8D8;
    outline: none;
    border-radius: 0.25rem;
    margin-top: 0.5rem;
    font-size: 1rem;
}

.general-input label{

}

.general-send-noti {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    align-items: center;
    border: 2px solid var(--border-color);
    border-radius: 0.625rem;
    background-color: white;

    padding: 1rem;
}

.general-send-noti p {
    color: #1A1919;
    font-size: 1rem;
}

a {
    color: var(--steel-blue);
    font-size: 1rem;
    font-weight: 600;
}

.s-general-cat {
    margin-top: 2rem;
}

.common-para {
    font-size: 0.75rem;
    color: #1A1919;
    font-weight: bold;
}

.border-service {
    border: 2px solid var(--border-color);
    border-radius: 0.625rem;
    background-color: white;
}

.service-general-body {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
}

.service-general-body p {
    color: #1A1919;
    font-size: 1rem;
    font-weight: 600;
    width: 20%;
}

.edit-link {
    display: flex;
    align-items: center;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: .5;
}

.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
}

.modal {
    z-index: 100;
    background: white;
    position: relative;
    margin: 1.75rem auto;
    border-radius: 3px;
    max-width: 500px;
    padding: 2rem;
}

.modal-header {
    display: flex;
    justify-content: flex-end;
}

.modal-close-button {
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    opacity: .3;
    cursor: pointer;
    border: none;
}