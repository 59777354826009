.query-pop-up {
    background-color: var(--steel-blue);
    display: flex;
    justify-content: space-between;
    border-top-left-radius: 0.8rem;
    border-top-right-radius: 0.8rem;
    padding: 1rem;
    align-items: center;
}

.query-body {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.query-pop-up h4 {
    color: #FFFFFF;
    font-size: 1rem;
}

.query-pop-up span {
    color: #FFFFFF;
    font-size: 1rem;
    cursor: pointer;
}

.query-details {
    width: 29%;
    margin: 2rem 1rem 0rem 1rem;
    /* padding: 1rem; */
}

.query-para p {
    color: #989898;
    font-size: 0.75rem;
}

.query-para h4 {
    font-size: 0.9rem;
    color: #1A1919;
    font-weight: normal;
}

.query-btn-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid var(--border-color);
    padding: 1rem;
}

.query-mark-btn {
    display: flex;
    align-items: center;
}

.mark-query {
    background-color: white;
    color: white;
    border-radius: 2rem;
    padding: 0.5rem 2rem 0.5rem 2rem;
    border: 1px solid var(--steel-blue);
    outline: none;
    /* font-size: 1rem; */
    color: var(--steel-blue);
    margin-right: 1rem;
}