.dash-container {
    width: 100%;
    display: flex;
    background-color: #FCFCFC;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-top: 5rem;
}

.side-left {
    width: 18%;
    background-color: var(--steel-blue);
    /* height: 100vh; */
    padding: 1rem;
}

.side-left ul {
    list-style: none;
}

.side-left ul .tablink {
    margin-top: 1rem;
    padding: 1rem;
    cursor: pointer;
    border-radius: 0.2rem;
    display: flex;
    align-items: center;
    color: white;
    font-size: 0.875rem;
}

.side-left ul .tablink span {
    margin-left: .5rem;
}

.accordian-tab {
    display: flex;
    /* align-items: center; */
}

.accordian-list {
    display: flex;
    flex-direction: column;
    list-style: none;
}


.accordian-list a {
    display: flex;
    text-decoration: none !important;
    color: white;
    align-items: center;
    font-size: 0.875rem;
}

.accordian-list li {
    display: flex;
    margin: 0.8rem 0rem 0 1rem;
    align-items: center;
    font-size: 0.875rem;
}

.plus {
    margin-left: 1.5rem;
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 50%;
    background-color: var(--picton-blue);
    display: flex;
    justify-content: center;
    text-align: center;
    /* font-size: 1rem; */
}

.active-side {
    background-color: var(--tufts-blue);
    color: white;
}

.side-right {
    width: 80%;
    padding: 1rem;
}


@media (max-width:768px) {
    .dash-container {}

    .side-left ul .tablink {
        padding: 0.5rem;
        /* align-items: flex-start; */
    }

    .side-left ul .tablink span {
        margin: 0;
        display: none;
    }

    .plus {
        margin: 0;
    }

    .side-right {
        width: 100%;
    }
}