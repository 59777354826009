
.user-wallet-container {
    padding: 2rem 0;
  }
  .user-wallet {
    background-color: white;
    border: 1px solid var(--argent);
    border-radius: 0.5rem;
  }
  .user-wallet-content {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .user-wallet-content1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .user-wallet-content p {
    font-size: 1rem;
    color: #989898;
  }
  .user-wallet-content h4 {
    font-size: 1.5rem;
    margin-left: 1rem;
  }
  .user-wallet-content h4 small {
    font-size: 0.75rem;
    color: #989898;
    font-weight: normal;
  }
  
  .user-wallet {
    background-color: white;
    border: 1px solid var(--argent);
    border-radius: 0.5rem;
  }
  
  .user-wallet-content p {
    font-size: 1rem;
    color: #989898;
  }
  .user-wallet-content h4 {
    font-size: 1.5rem;
  }
  .user-wallet-content h4 small {
    font-size: 0.75rem;
    color: #989898;
    font-weight: normal;
  }